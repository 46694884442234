<template>

  <div style="height: 300px">
     <div class="container-loading" v-show="loading">
        <b-spinner label="Loading..."></b-spinner>
    </div>

    <p class="title-chart-funil" v-show="!loading">{{ $t('chart.funnel_checkout') }}</p>
    <div class="container-funnel" v-show="!loading">
      <div class="funnel">
        <div class="container-data-funnel">
          <div class="item-funnel funnel-acessos"
               v-b-tooltip.hover
               :title="`${$t('chart.checkout_access')}: ${data.access}`"
          >
          </div>
          <div class="item-funnel funnel-iniciou"
               v-b-tooltip.html
               :title="`${$t('chart.did_init_payment')}: ${data.started_payment}`"
          >
          </div>
          <div class="item-funnel funnel-tentativa"
               v-b-tooltip.html
               :title="`${$t('chart.try_payment')}: ${data.payment_attempt}`">
          </div>
          <div class="item-funnel funnel-realizado end-item-funnel"
               v-b-tooltip.html
               :title="`${$t('chart.did_payment')}: ${data.payment_made}`">
          </div>
        </div>
      </div>

      <div class="container-label-funnel">
        <label class="">
          <span class="">{{ $t('chart.checkout_access') }}</span>
          <div class="legendValue d-flex align-items-center">
            <div class="mark-label" style="background-color: #009488"></div>
            <span  class="ml-4 value_item_rel">{{data.access}}</span>
          </div>
        </label>
        <label class="">
          <span class="">{{ $t('chart.did_init_payment') }}</span>
          <div class="legendValue d-flex align-items-center">
            <div class="mark-label" style="background-color: rgba(0, 228, 160, 0.7)"></div>
            <span  class="ml-4 value_item_rel">{{data.started_payment}}</span>
          </div>
        </label>
        <label class="">
          <span class="">{{ $t('chart.try_payment') }}</span>
          <div class="legendValue d-flex align-items-center">
            <div class="mark-label" style="background-color: rgba(0, 228, 160, 0.5)"></div>
            <span  class="ml-4 value_item_rel">{{data.payment_attempt}}</span>
          </div>
        </label>
        <label class="">
          <span class="">{{ $t('chart.did_payment_funnel') }}</span>
          <div class="legendValue d-flex align-items-center">
            <div class="mark-label" style="background-color: rgba(0, 228, 160, 0.3)"></div>
            <span  class="ml-4 value_item_rel">{{data.payment_made}}</span>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>

import ReportService from '@/services/resources/ReportService';
const Report = ReportService.build()

export default {
  name: "FunilChart",
  props: ['filter'],
  data:()=>{
    return {
      loading: true,
      data: {
        access: 0,
        started_payment: 0,
        payment_attempt: 0,
        payment_made: 0
      }
    }
  },
  watch: {
    filter: function(){
      this.loading = true
      this.read()
    }
  },
  methods: {
    read(){
      let filter = this.filter
      Report.search({url:'funnel', ...filter})
          .then((response)=>{

            this.data = Object.assign({}, {
              access: response.access,
              started_payment: response.started_payment,
              payment_attempt: response.payment_attempt,
              payment_made: response.payment_made
            });
            this.loading = false
          });
    }
  }
}
</script>

<style scoped>

.funnel-acessos{
  background-color: #009488;
}
.funnel-iniciou{
  background: rgba(0, 228, 160, 0.7);
}
.funnel-tentativa{
  background: rgba(0, 228, 160, 0.5);
}
.funnel-realizado{
  background: rgba(0, 228, 160, 0.3);
}

.mark-label{
  margin: 15px 0; width: 24px; height: 4px;
}


.container-label-funnel{
  height: 274px;
  z-index: 1000;
}

.container-label-funnel>label{
  margin-bottom: 25px !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #81858E;
}

.container-funnel{
  margin: 15px 0;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

@media (max-width: 600px) {

  .funnel {
    flex-grow: 0;
    max-width: 75.33333%;
    flex-basis: 66.66667%;
  }
}

.funnel{
  flex-grow: 0;
  max-width: 45.33333%;
  flex-basis: 45.33333%;
}

.container-data-funnel {
  width: 100%;
  position: relative;
}
*, *::before, *::after {
  box-sizing: inherit;
}

.container-data-funnel:after {
  right: 0;
  border-top: 285px solid transparent;
  border-right: 114px solid #FFF;
}

.item-funnel span {
  margin: 0;
  font-size: 12px;
  font-family: Lato;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.item-funnel {
  width: 100%;
  color: #FFF;
  height: 68px;
  display: flex;
  flex-flow: column;
  align-items: center;
  border-bottom: 8px solid #FFF;
  justify-content: center;
  border-radius: 15px;
}

.container-data-funnel:before {
  left: 0  !important;;
  border-top: 285px solid transparent !important;
  border-left: 114px solid #FFF !important;;
}

.container-data-funnel:before, .container-data-funnel:after {
  top: 0;
  height: 100%;
  content: "";
  display: block;
  position: absolute;
}

.title-chart-funil{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}

.container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  animation: preload 5s forwards linear infinite alternate;
}
</style>