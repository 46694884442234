<template>
  <div class="container">
    <BaseHeader :title="$t('views.seller.reports.text_1428')"> </BaseHeader>
    <div class="container-table">
      <div class="d-flex align-items-center flex-acoes">
        <div class="datas">
          <date-range-picker
            ref="picker"
            opens="right"
            :locale-data="localeDate"
            :showWeekNumbers="false"
            :showDropdowns="true"
            :autoApply="false"
            :ranges="dateRanges"
            @update="dateRanger"
            v-model="dateRange"
            :maxDate="maxDate"
          >
            <template
              v-if="dateRange.startDate && dateRange.endDate"
              v-slot:input="picker"
              style="min-width: 350px"
            >
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </template>
            <template
              v-else
              v-slot:input
              class="btn-table"
              :title="$t('views.seller.reports.text_1429')"
            >
              {{ $t('views.seller.reports.text_1429') }}
            </template>
          </date-range-picker>
        </div>
        <div class="ml-4 col-5">
          <multiselect
            v-model="productFilter"
            id="ajax"
            label="name"
            track-by="id"
            :placeholder="$t('views.seller.reports.text_1430')"
            selectLabel=""
            deselectLabel=""
            selectedLabel="✔️"
            open-direction="bottom"
            :options="products"
            :multiple="true"
            :searchable="true"
            :loading="loading"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="false"
            :options-limit="10"
            :show-no-results="true"
            :hide-selected="true"
            @update:modelValue="appliedFilter($event)"
            @search-change="aux_product_debounce"
          >
            <template v-slot:noOptions>
              <span>{{ $t('views.seller.reports.text_1330') }}</span>
            </template>
            <template v-slot:noResult>
              <span>{{ $t('views.seller.reports.text_1331') }}</span>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="container-reports">
        <Container title="Checkout" :totalDiffDays="totalDiffDays">
          <Checkout :filter="filter" />
        </Container>
        <br />
        <Container
          :title="$t('views.seller.reports.text_1332')"
          :totalDiffDays="totalDiffDays"
        >
          <Contracts :filter="filter" />
        </Container>
        <br />
        <Container :title="$t('views.seller.reports.text_1431')" :totalDiffDays="totalDiffDays">
          <Revenues :filter="filter" />
          <br />

          <!-- <ChartsPaidRefused :filter="filter" /> -->
        </Container>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-chartjs";
import Multiselect from "vue-multiselect";
import Container from "@/components/Seller/Reports/Container.vue";
import Moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import Checkout from "@/components/Seller/Reports/Checkout.vue";
import Revenues from "@/components/Seller/Reports/Revenues.vue";

import Contracts from "@/components/Seller/Reports/Contracts.vue";
import ProductService from "@/services/resources/ProductService";

import gtagSend from '@/utils/gtagSend';

const serviceProduct = ProductService.build();
const moment = Moment;

export default {
  name: "Reports",
  components: {
    DateRangePicker,
    Checkout,
    Multiselect,
    Container,
    Contracts,
    Revenues,
  },
  data: () => {
    return {
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange: {
        startDate: moment().subtract("days", 7),
        endDate: moment().subtract("days", 1),
      },
      filter: {
        products: [],
        startDate: moment().subtract("days", 7),
        endDate: moment().subtract("days", 1),
      },
      productFilter: [],
      products: [],
      loading: false,
      totalDiffDays: null,
      maxDate: "",
    };
  },
  created(){
    if(this.$store.getters.setPanel){
      this.$router.push("/dashboard");
    }
  },
  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        [this.$t('locale_date.today')]: [today, today],
        [this.$t('locale_date.yesterday')]: [yesterday, yesterday],
        [this.$t('locale_date.last_week')]: [lastWeek, today],
        [this.$t('locale_date.current_month')]: [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        [this.$t('locale_date.current_year')]: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        [this.$t('locale_date.last_month')]: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        [this.$t('locale_date.every_period')]: [
          new Date(2020, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
  },
  watch: {
    productFilter: function (products) {
      this.appliedFilter(products)
      this.filter = Object.assign({}, this.filter, {
        products: products.map((item) => item.id),
      });
    },
  },
  methods: {
    appliedFilter(products){
      
     gtagSend('relatorio_produto_filtrado',{
      ID_Cliente: this.$store.getters.getProfile.id || JSON.parse(localStorage.user).id,
      ID_Produto:products[products.length - 1].id,
      Nome_Produto:products[products.length - 1].name
     })
      
    },
    dateRanger(dateRange) {
      let end = moment(dateRange.endDate);
      let start = moment(dateRange.startDate);

      this.totalDiffDays = end.diff(start, "days") + 1;

      let startDate = moment
        .tz(dateRange.startDate, "America/Sao_Paulo")
        .format("YYYY-MM-DDT00:00:00-03:00");
      let endDate = moment
        .tz(dateRange.endDate, "America/Sao_Paulo")
        .format("YYYY-MM-DDT23:59:59-03:00");

      this.filter = Object.assign({}, this.filter, {
        startDate: startDate,
        endDate: endDate,
      });
    },
    fetchProducts(search = null) {
      let data = {
        list: true,
      };
      if (search) data.search = search;
      this.products = [];
      serviceProduct
        .search(data)
        .then((response) => {
          response.forEach((element) => {
            this.products.push({ id: element.id, name: element.name });
          });
        })
        .catch(() => {
          this.products = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    aux_product_debounce(query) {
      this.loading = true;
      this.search = query;
      setTimeout(() => {
        this.fetchProducts(this.search);
      }, 500);
    },
    sendGtag(){
        gtagSend('relatorios_acessados ', {
            ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
        });
    },
  },
  mounted() {
    let startDate = moment
      .tz(this.dateRange.startDate, "America/Sao_Paulo")
      .format("YYYY-MM-DDT00:00:00-03:00");
    let endDate = moment
      .tz(this.dateRange.endDate, "America/Sao_Paulo")
      .format("YYYY-MM-DDT23:59:59-03:00");

    this.maxDate = moment().add("days", 1).format("YYYY-MM-DD");

    this.filter = Object.assign({}, this.filter, {
      startDate,
      endDate,
    });

    this.dateRange = {
      startDate,
      endDate
    };

    let end = moment(this.dateRange.endDate);
    let start = moment(this.dateRange.startDate);
    this.totalDiffDays = end.diff(start, "days") + 1;
    this.sendGtag();
  },
};
</script>

<style scoped>
.flex-acoes {
  margin: 33px 0;
}
.container-reports {
  margin-top: 50px;
}
</style>